body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@primaryColor: rgba(37, 1, 63, 0.52);@secondaryColor: #ab00aa77;@dark: #222;@textColor: white;@linkColor: #61dafb;@darkGray: #444;@midGray: #777;@lightGray: #aaa;@slateGray: #30404d;@coldGray: #455;@rustGray: #544;@warmGray: #664;@primaryGrad: linear-gradient(45deg, @primaryColor, black);@secondaryGrad: linear-gradient(45deg,indigo,black);